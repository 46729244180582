import React, { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';
import { Button, Card, Spinner } from '../../../components';
import IconDictionary from '../../../components/Icons/icons';
import { getTokenAccountInformation } from '../../../helpers/token.helper';
import { ToastNotify } from '../../../components/Toast/toast';
import { ApiInputAutomation } from '../api';
import { importResultsProps } from '../types';
import { dictionaryError } from '../../../helpers/utils.helper';

function AlertConfiguration({ open }: { open: boolean }) {
  const isOpen = open;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium leading-6 text-gray-900 flex items-center gap-3"
                >
                  <IconDictionary
                    name="AlertCircle"
                    color="#F7C04A"
                    size={24}
                  />{' '}
                  Configuração de importação inexistente
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    Não há configurações de importação salvas para a empresa
                    atual. Por favor, click no botão abaixo para prosseguir para
                    a tela de cadastro.{' '}
                  </p>
                </div>

                <div className="mt-5 flex w-full justify-center">
                  <Link to="/input-automations">
                    <Button variant="primary-strong" className="w-36">
                      Configurar
                    </Button>
                  </Link>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function AlertImport({
  open,
  close,
  submit,
}: {
  open: boolean;
  close: () => void;
  submit: () => void;
}) {
  const isOpen = open;

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-40" onClose={close}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-md sm:text-lg font-medium leading-6 text-gray-900 flex items-center gap-3"
                >
                  Deseja realmente iniciar a importação desta planilha ?
                </Dialog.Title>

                <div className="mt-6 flex w-full justify-center gap-3">
                  <Button
                    variant="outline-primary"
                    className="w-36"
                    onClick={close}
                  >
                    Não
                  </Button>
                  <Button
                    variant="primary-strong"
                    className="w-36"
                    onClick={submit}
                  >
                    Sim
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

const ImportOpportunity: React.FC = () => {
  const [resultsImport, setResultsImport] = useState<importResultsProps | null>(
    null
  );
  const [files, setFiles] = useState<any>();

  const { notify } = ToastNotify();
  const accountProps = getTokenAccountInformation();

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
  const [isLoadingValid, setIsLoadingValid] = useState(false);
  const [showPopUpAlert, setShowPopUpAlert] = useState(false);
  const [showPopUpConfirm, setShowPopUpConfirm] = useState(false);

  const createFile = async () => {
    setIsLoadingSubmit(true);
    setShowPopUpConfirm(false);
    const fileForm = files;
    const formData: any = new FormData();

    try {
      formData.append('companie_id', accountProps.companie_id);
      formData.append('file', fileForm[0]);
    } catch {
      setIsLoadingSubmit(false);
    }

    const res = await ApiInputAutomation.createImport(formData);
    if (typeof res !== 'string') {
      setResultsImport(res);
      notify({ message: 'Planilha enviada com sucesso', type: 'Success' });
      setIsLoadingSubmit(false);
    } else {
      notify({ message: dictionaryError(res), type: 'Error' });
      setIsLoadingSubmit(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles: 1,
    multiple: false,
    onDrop: (acceptedFiles: any) => {
      const fileForm = acceptedFiles.map((f: any) =>
        Object.assign(f, {
          preview: URL.createObjectURL(f),
        })
      );
      setFiles(fileForm);
      setShowPopUpConfirm(true);
    },
  });

  useEffect(() => {
    const checkIsValid = async () => {
      setIsLoadingValid(true);
      const res = await ApiInputAutomation.companyHasConfiguration(
        accountProps.companie_id
      );

      if (typeof res !== 'string' && res.length === 0) {
        setShowPopUpAlert(true);
      }
      setIsLoadingValid(false);
    };
    checkIsValid();
  }, []);

  return (
    <>
      <AlertConfiguration open={showPopUpAlert} />
      <AlertImport
        close={() => setShowPopUpConfirm(false)}
        open={showPopUpConfirm}
        submit={createFile}
      />
      <div className="w-full min-h-screen h-fit">
        <div className="flex gap-3 items-center text-primary">
          <IconDictionary name="Importações" size={24} />
          <strong className="text-lg text-black">
            Importação de oportunidades
          </strong>
        </div>
        <Card className="w-full p-7 mt-6 mb-3 flex-col min-h-[80vh] 2xl:min-h-[85vh]">
          <strong className="text-xl mb-2">Importação de oportunidades</strong>
          <p className="text-xs sm:text-sm">
            Importação de Leads e possíveis oportunidades de negócio
          </p>
          {isLoadingValid || isLoadingSubmit ? (
            <div className="w-full h-[60vh] flex items-center justify-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="flex gap-3 items-center mt-5">
                <p className="text-xs">
                  Para isto, faça download da planilha padrão e informe os dados
                </p>
                <IconDictionary name="Excel" color="#00A374" size={24} />
              </div>
              <div className="flex flex-col items-center w-full mt-7">
                <div
                  {...getRootProps()}
                  className={classNames(
                    'border-[1px] border-dashed border-[#aaa] p-4 rounded-md cursor-pointer bg-background mb-4 relative w-full h-24 flex items-center justify-center',
                    {
                      'border-[#DC7E19] bg-primary-400/10': isDragActive,
                    }
                  )}
                >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <p className="font-light text-sm text-primary">
                      Solte o arquivo aqui...
                    </p>
                  ) : (
                    <p className="font-light text-sm text-center text-[#555]">
                      Arraste e solte aqui ou clique para selecionar um arquivo
                    </p>
                  )}
                </div>
              </div>
              {resultsImport !== null && (
                <>
                  <div className="text-sm">
                    <strong>Resultado da importação:</strong>
                    <p className="ml-4">
                      • Total registros:{' '}
                      {resultsImport.total_created +
                        resultsImport.errors.total_error}
                    </p>
                    <p className="ml-4 text-green">
                      • Total importado: {resultsImport.total_created}
                    </p>
                    <p className="ml-4 text-red">
                      • Total de erros: {resultsImport.errors.total_error}
                    </p>
                  </div>
                  {resultsImport.errors.descriptions_error.length > 0 && (
                    <div className="max-w-full overflow-x-auto">
                      <div className="min-w-[400px] grid grid-cols-5 text-sm mt-7">
                        <strong className="pl-2 pb-1 border-b border-r border-solid border-gray-300">
                          Linha
                        </strong>
                        <strong className="pl-2 pb-1 border-b border-r border-solid border-gray-300">
                          Coluna
                        </strong>
                        <strong className="pl-2 pb-1 col-span-3 border-b border-solid border-gray-300">
                          Erro
                        </strong>
                        {resultsImport.errors.descriptions_error.map(
                          (error, index) => (
                            <>
                              <p
                                className={classNames([
                                  'pl-2 py-1 text-xs',
                                  resultsImport.errors.descriptions_error
                                    .length -
                                    1 ===
                                  index
                                    ? 'border-r border-l-0 border-solid border-gray-300'
                                    : 'border-b border-r border-solid border-gray-300',
                                ])}
                              >
                                {error.line}
                              </p>
                              <p
                                className={classNames([
                                  'pl-2 py-1 text-xs',
                                  resultsImport.errors.descriptions_error
                                    .length -
                                    1 ===
                                  index
                                    ? 'border-r border-l-0 border-solid border-gray-300'
                                    : 'border-b border-r border-solid border-gray-300',
                                ])}
                              >
                                {error.column}
                              </p>
                              <p
                                className={classNames([
                                  'pl-2 py-1 text-xs col-span-3',
                                  resultsImport.errors.descriptions_error
                                    .length -
                                    1 ===
                                  index
                                    ? 'border-l-0 border-solid border-gray-300'
                                    : 'border-b border-solid border-gray-300',
                                ])}
                              >
                                {error.error}
                              </p>
                            </>
                          )
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default ImportOpportunity;
